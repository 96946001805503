
import React, { useEffect, useState } from "react";
import '../assets/styles/styles.css'
import image1 from '../assets/images/1.jpg'
import video1 from '../assets/videos/v1.mp4';
import video2 from '../assets/videos/video2.mp4';
import video3 from '../assets/videos/video3.mp4';
import video4 from '../assets/videos/video4.mp4';
import video5 from '../assets/videos/video3.mp4';
import 'font-awesome/css/font-awesome.min.css';
import Logo from '../assets/images/logo-light.png';
import {
    Modal,
} from "reactstrap";


const Main: React.FC = () => {
    const [currentSection, SetCurrentSection] = useState('')
    const [currentVideoCaption, setCurrentVideoCaption] = useState('')
    const [screenWidth, setScreenWidth] = useState('');
    const [modal_fullscreen, setmodal_fullscreen] = useState(false);

    const handleClicknavbar = () => {
        setmodal_fullscreen(!modal_fullscreen);
    };

    useEffect(() => {
        return () => {
            window.addEventListener("scroll", function () {
                const fullHeight = document.documentElement.scrollHeight;
                const innerHeight = window.outerHeight;
                const currentTopHeight = window.pageYOffset;
                const height = (fullHeight - innerHeight);
                const postiongRatio = (height - currentTopHeight) / height;
                const percentagePostion = 100 - (postiongRatio * 100);
                console.log('percentagePostion', percentagePostion)
                const screenWidth = window.outerWidth
                console.log("screen-width", screenWidth)
                if (screenWidth < 768) {
                    setScreenWidth('small')
                    if (percentagePostion < 2.9999999) {
                        SetCurrentSection('section1')
                    } else if ((percentagePostion > 3) && (percentagePostion < 22.999999)) {
                        SetCurrentSection('section2-1')
                    } else if ((percentagePostion > 23) && (percentagePostion < 39.99999999)) {
                        SetCurrentSection('section2-2')
                    }
                    else if ((percentagePostion > 40) && (percentagePostion < 59.999999999)) {
                        SetCurrentSection('section3')
                        if ((percentagePostion > 40.0001) && (percentagePostion < 43.999999999)) {
                            setCurrentVideoCaption('section3-1')
                        } else if ((percentagePostion > 44) && (percentagePostion < 47.999999999)) {
                            setCurrentVideoCaption('section3-2')
                        } else if ((percentagePostion > 48) && (percentagePostion < 51.999999999)) {
                            setCurrentVideoCaption('section3-3')
                        }
                        else if ((percentagePostion > 52) && (percentagePostion < 55.999999999)) {
                            setCurrentVideoCaption('section3-4')
                        }
                        else if ((percentagePostion > 56) && (percentagePostion < 60.999999999)) {
                            setCurrentVideoCaption('section3-5')
                        }
                    }
                    else if ((percentagePostion > 61) && (percentagePostion < 77.999999)) {
                        SetCurrentSection('section4')
                        if ((percentagePostion > 61.0001) && (percentagePostion < 63.999999999)) {
                            setCurrentVideoCaption('section4-1')
                        } else if ((percentagePostion > 64) && (percentagePostion < 67.999999999)) {
                            setCurrentVideoCaption('section4-2')
                        } else if ((percentagePostion > 68) && (percentagePostion < 67.999999999)) {
                            setCurrentVideoCaption('section4-3')
                        }
                        else if ((percentagePostion > 68) && (percentagePostion < 72.999999999)) {
                            setCurrentVideoCaption('section4-4')
                        }
                        else if ((percentagePostion > 73) && (percentagePostion < 77.999999999)) {
                            setCurrentVideoCaption('section4-5')
                        }
                    } else if ((percentagePostion > 78) && (percentagePostion < 82.999999)) {
                        SetCurrentSection('section5')
                    }

                } else {
                    setScreenWidth('large')
                    if (percentagePostion < 6.5) {
                        SetCurrentSection('section1')
                    } else if ((percentagePostion > 6.51) && (percentagePostion < 11.999999)) {
                        SetCurrentSection('section2-1')
                    } else if ((percentagePostion > 12) && (percentagePostion < 22.999999)) {
                        SetCurrentSection('section2-2')
                    }
                    else if ((percentagePostion > 23) && (percentagePostion < 53.999999999)) {
                        SetCurrentSection('section3')
                        if ((percentagePostion > 23.011) && (percentagePostion < 29.999999999)) {
                            setCurrentVideoCaption('section3-1')
                        } else if ((percentagePostion > 30) && (percentagePostion < 35.999999999)) {
                            setCurrentVideoCaption('section3-2')
                        } else if ((percentagePostion > 36) && (percentagePostion < 41.999999999)) {
                            setCurrentVideoCaption('section3-3')
                        }
                        else if ((percentagePostion > 42) && (percentagePostion < 48.999999999)) {
                            setCurrentVideoCaption('section3-4')
                        }
                        else if ((percentagePostion > 49) && (percentagePostion < 53.999999999)) {
                            setCurrentVideoCaption('section3-5')
                        }
                    } else if ((percentagePostion > 54) && (percentagePostion < 74.9999999)) {
                        SetCurrentSection('section4')
                        if ((percentagePostion > 54.011) && (percentagePostion < 57.999999999)) {
                            setCurrentVideoCaption('section4-1')
                        } else if ((percentagePostion > 58) && (percentagePostion < 62.999999999)) {
                            setCurrentVideoCaption('section4-2')
                        } else if ((percentagePostion > 63) && (percentagePostion < 67.999999999)) {
                            setCurrentVideoCaption('section4-3')
                        }
                        else if ((percentagePostion > 68) && (percentagePostion < 71.999999999)) {
                            setCurrentVideoCaption('section4-4')
                        }
                        else if ((percentagePostion > 72) && (percentagePostion < 74.999999999)) {
                            setCurrentVideoCaption('section4-5')
                        }
                    } else if ((percentagePostion > 75) && (percentagePostion < 80.9999999)) {
                        SetCurrentSection('section5')
                    }
                }
            })
        }
    }, [])

    console.log('currentSection', currentSection)
    return (
        <div
            className="body"
        >
            <div className="d-flex flex-wrap justify-content-between transparent-bg-color postion-fixed-header">
                <div className="px-0 transparent-bg-color">
                    <img src={Logo} className="logo-icon" />
                </div>
                {
                    modal_fullscreen ?
                        <p className="nav-icon nav-icon-close mouse-hover-nav" onClick={() => handleClicknavbar()}><i className="fa fa-close fa-sm"></i></p>
                        :
                        <p className="nav-icon nav-icon-open mouse-hover-nav" onClick={() => handleClicknavbar()}><i className="fa fa-bars fa-sm"></i></p>
                }
            </div>

            <div className={`section-01 show`} id="section-01">
                <video className="mainContainer" autoPlay muted loop id="myVideo">
                    <source src={video4} type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
                <div className="main-content">
                    <span>Anything On AI</span>
                </div>

            </div>

            <div className={`section-02-1 ${(currentSection == 'section2-1') ? 'show' : null}`} id="section-02">
                <div className="section-02-subContainer why-margin-left-right">
                    <div className="row">
                        <p className="why-title mb-4"> Why Video Captioning ?</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md why-container-1">
                            <div className="why-container-new">
                                <div className="why-background-card-1 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-circle-o fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-1">
                                    Lorem Ipsum
                                </p>
                                <p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                            </div>
                        </div>

                        <div className="col-md why-container-2">
                            <div className="why-container-new">
                                <div className="why-background-card-2 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-window-maximize fa-sm"></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-2">
                                    Lorem Ipsum
                                </p>
                                <p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                            </div>
                        </div>

                        <div className="col-md why-container-3">
                            <div className="why-container-new">
                                <div className="why-background-card-3 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-square-o fa-sm"></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-3">
                                    Lorem Ipsum
                                </p>
                                <p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                            </div>
                        </div>

                        <div className="col-md why-container-4">
                            <div className="why-container-new">
                                <div className="why-background-card-4 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-star-o fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-4">
                                    Lorem Ipsum
                                </p>
                                <p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 mb-4"></div>
                    <div className="row">
                        <div className="col-md why-container-5">
                            <div className="why-container-new">
                                <div className="why-background-card-5 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-link fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-5">
                                    Lorem Ipsum
                                </p>
                                <p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                            </div>
                        </div>

                        <div className="col-md why-container-6">
                            <div className="why-container-new">
                                <div className="why-background-card-6 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-circle-o-notch  fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-5">
                                    Lorem Ipsum
                                </p>
                                <p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                            </div>
                        </div>

                        <div className="col-md why-container-7">
                            <div className="why-container-new">
                                <div className="why-background-card-7 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-paperclip fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-7">
                                    Lorem Ipsum
                                </p>
                                <p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                            </div>
                        </div>

                        <div className="col-md why-container-8">
                            <div className="why-container-new">
                                <div className="why-background-card-8 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-columns fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-8">
                                    Lorem Ipsum
                                </p>
                                <p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`section-02-2 ${(currentSection == 'section2-2') ? 'show' : null}`} id="section-02">
                <div className="section-02-2-layer-2">
                    {
                        (screenWidth == 'small') ?
                            <div className="section-02-subContainer why-margin-left-right">
                                <div className="row">
                                    <p className="why-title mb-4"> Why Video Captioning ?</p>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md why-container-1">
                                        <div className="why-container-new">
                                            <div className="why-background-card-1 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-circle-o fa-sm "></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-1">
                                                Lorem Ipsum
                                            </p>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-md why-container-2">
                                        <div className="why-container-new">
                                            <div className="why-background-card-2 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-window-maximize fa-sm"></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-2">
                                                Lorem Ipsum
                                            </p>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-md why-container-3">
                                        <div className="why-container-new">
                                            <div className="why-background-card-3 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-square-o fa-sm"></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-3">
                                                Lorem Ipsum
                                            </p>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-md why-container-4">
                                        <div className="why-container-new">
                                            <div className="why-background-card-4 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-star-o fa-sm "></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-4">
                                                Lorem Ipsum
                                            </p>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-4"></div>
                                <div className="row">
                                    <div className="col-md why-container-5">
                                        <div className="why-container-new">
                                            <div className="why-background-card-5 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-link fa-sm "></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-5">
                                                Lorem Ipsum
                                            </p>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-md why-container-6">
                                        <div className="why-container-new">
                                            <div className="why-background-card-6 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-circle-o-notch  fa-sm "></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-5">
                                                Lorem Ipsum
                                            </p>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-md why-container-7">
                                        <div className="why-container-new">
                                            <div className="why-background-card-7 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-paperclip fa-sm "></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-7">
                                                Lorem Ipsum
                                            </p>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-md why-container-8">
                                        <div className="why-container-new">
                                            <div className="why-background-card-8 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-columns fa-sm "></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-8">
                                                Lorem Ipsum
                                            </p>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                </div>

            </div>

            <div className={`section-03 ${(currentSection == 'section3') ? 'show' : null}`} id="section-03">

            </div>

            <div className={`section-04 ${(currentSection == 'section4') ? 'show' : null}`} id="section-04">
                Social Media management
            </div>

            <div className="section-05 show" id="section-05">
                <div className="section-02-subContainer why-margin-left-right">
                    <div className="row">
                        <p className="why-title mb-4"> Our Clients</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md why-container-1">
                            <div className="why-container-new">
                                <div className="why-background-card-1 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-circle-o fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-1">
                                    Lorem Ipsum
                                </p>
                                <p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                            </div>
                        </div>

                        <div className="col-md why-container-2">
                            <div className="why-container-new">
                                <div className="why-background-card-2 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-window-maximize fa-sm"></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-2">
                                    Lorem Ipsum
                                </p>
                                <p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                            </div>
                        </div>

                        <div className="col-md why-container-3">
                            <div className="why-container-new">
                                <div className="why-background-card-3 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-square-o fa-sm"></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-3">
                                    Lorem Ipsum
                                </p>
                                <p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                            </div>
                        </div>

                        <div className="col-md why-container-4">
                            <div className="why-container-new">
                                <div className="why-background-card-4 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-star-o fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-4">
                                    Lorem Ipsum
                                </p>
                                <p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-06 show" id="section-06">
                <div className="section-02-subContainer why-margin-left-right">
                    <div className="row">
                        <p className="why-title mb-4"> Our Case Study</p>
                    </div>
                    <div className="row mt-4">
                        {/* content */}
                    </div>
                </div>
            </div>

            <div className="section-07 show" id="section-07">
                <div className="section-02-subContainer why-margin-left-right">
                    <div className="row">
                        <p className="why-title mb-4"> Contact Us</p>
                    </div>
                    <div className="row mt-4">
                        {/* content */}
                    </div>
                </div>
            </div>
            <div className="section-08 show" id="section-08">
                <div className="section-02-subContainer why-margin-left-right">
                    <div className="row">
                        <p className="why-title mb-4"> About Us</p>
                    </div>
                    <div className="row mt-4">
                        {/* content */}
                    </div>
                </div>
            </div>

            <div className="footer show" id="section-09">
                <div className="section-02-subContainer why-margin-left-right">
                    <div className="row">
                        <p className="why-title mb-4"> Footer</p>
                    </div>
                    <div className="row mt-4">
                        {/* content */}
                    </div>
                </div>
            </div>
            {
                ((currentSection == 'section3') && (screenWidth == 'large')) ?
                    <div className={`section-03-1 ${(currentSection == 'section3') ? 'postion-fixed' : 'hidden'}`} id="section-03-1">

                        <div className="section-03-1-1 custom-height">
                            <p className="why-title margin-bottom-header"> Video Captioning</p>
                            <div className="video-box">
                                {/* <img src={image1} className="video-image" id="vid-img" /> */}
                                {/* <video className="video-image" id="vid-img" autoPlay controls>
                                    <source src={video2} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video> */}

                                <video
                                    className="video-image  margin-bottom-video"
                                    playsInline
                                    loop
                                    muted
                                    autoPlay={true}
                                    src={`${(currentVideoCaption == 'section3-1') ? video1 : (currentVideoCaption == 'section3-2') ? video2 : (currentVideoCaption == 'section3-3') ? video3 : (currentVideoCaption == 'section3-4') ? video4 : (currentVideoCaption == 'section3-5') ? video5 : null}`}
                                />
                            </div>
                        </div>
                        <div className="section-03-1-2">
                            <div className="video-subtitle-box">
                                <div className={`${(currentVideoCaption == 'section3-1') ? 'active-text' : null}`} id="text1">Video Captioning Subtitle 1</div>
                                <div className={`${(currentVideoCaption == 'section3-2') ? 'active-text' : null}`} id="text2">Video Captioning Subtitle 2</div>
                                <div className={`${(currentVideoCaption == 'section3-3') ? 'active-text' : null}`} id="text3">Third captioning goes here</div>
                                <div className={`${(currentVideoCaption == 'section3-4') ? 'active-text' : null}`} id="text4">Fourth captioning goes here</div>
                                <div className={`${(currentVideoCaption == 'section3-5') ? 'active-text' : null}`} id="text5">Fifth captioning goes here</div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                ((currentSection == 'section4') && (screenWidth == 'large')) ?
                    <div className={`section-03-1 ${(currentSection == 'section4') ? 'postion-fixed' : 'hidden'}`} id="section-03-1">

                        <div className="section-03-1-1 custom-height">
                            <p className="why-title margin-bottom-header"> Social Media Management</p>
                            <div className="video-box">

                                <video
                                    className="video-image  margin-bottom-video"
                                    playsInline
                                    loop
                                    muted
                                    autoPlay={true}
                                    src={`${(currentVideoCaption == 'section4-1') ? video1 : (currentVideoCaption == 'section4-2') ? video2 : (currentVideoCaption == 'section4-3') ? video3 : (currentVideoCaption == 'section4-4') ? video4 : (currentVideoCaption == 'section4-5') ? video5 : null}`}
                                />
                            </div>
                        </div>
                        <div className="section-03-1-2">
                            <div className="video-subtitle-box">
                                <div className={`${(currentVideoCaption == 'section4-1') ? 'active-text' : null}`} id="text1">Social Media Management Sub 1</div>
                                <div className={`${(currentVideoCaption == 'section4-2') ? 'active-text' : null}`} id="text2">Social Media Management Sub 2</div>
                                <div className={`${(currentVideoCaption == 'section4-3') ? 'active-text' : null}`} id="text3">Third SMM title goes here</div>
                                <div className={`${(currentVideoCaption == 'section4-4') ? 'active-text' : null}`} id="text4">Fourth SMM title goes here</div>
                                <div className={`${(currentVideoCaption == 'section4-5') ? 'active-text' : null}`} id="text5">Fifth SMM title goes here</div>
                            </div>
                        </div>
                    </div>
                    : null
            }

            {
                ((currentSection == 'section2-2') && (screenWidth == 'large')) ?
                    <div className="fixed-position-02-2">
                        <div className={`section-02-subContainer why-margin-left-right`} >
                            <div className="row">
                                <p className="why-title mb-4"> Why Video Captioning ?</p>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md why-container-1">
                                    <div className="why-container-new">
                                        <div className="why-background-card-1 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-circle-o fa-sm "></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-1">
                                            Lorem Ipsum
                                        </p>
                                        <p className="why-description-1">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-md why-container-2">
                                    <div className="why-container-new">
                                        <div className="why-background-card-2 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-window-maximize fa-sm"></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-2">
                                            Lorem Ipsum
                                        </p>
                                        <p className="why-description-1">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-md why-container-3">
                                    <div className="why-container-new">
                                        <div className="why-background-card-3 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-square-o fa-sm"></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-3">
                                            Lorem Ipsum
                                        </p>
                                        <p className="why-description-1">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-md why-container-4">
                                    <div className="why-container-new">
                                        <div className="why-background-card-4 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-star-o fa-sm "></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-4">
                                            Lorem Ipsum
                                        </p>
                                        <p className="why-description-1">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4 mb-4"></div>
                            <div className="row">
                                <div className="col-md why-container-5">
                                    <div className="why-container-new">
                                        <div className="why-background-card-5 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-link fa-sm "></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-5">
                                            Lorem Ipsum
                                        </p>
                                        <p className="why-description-1">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-md why-container-6">
                                    <div className="why-container-new">
                                        <div className="why-background-card-6 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-circle-o-notch  fa-sm "></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-5">
                                            Lorem Ipsum
                                        </p>
                                        <p className="why-description-1">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-md why-container-7">
                                    <div className="why-container-new">
                                        <div className="why-background-card-7 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-paperclip fa-sm "></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-7">
                                            Lorem Ipsum
                                        </p>
                                        <p className="why-description-1">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-md why-container-8">
                                    <div className="why-container-new">
                                        <div className="why-background-card-8 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-columns fa-sm "></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-8">
                                            Lorem Ipsum
                                        </p>
                                        <p className="why-description-1">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                ((currentSection == 'section3') && (screenWidth == 'small')) ?
                    <div className={`section-03-1 ${(currentSection == 'section3') ? 'postion-fixed' : 'hidden'}`} id="section-03-1">
                        <div className={`section-03-1 top-margin-md`} id="section-03-1">

                            <div className="row">
                                <div className="col-md">
                                    <p className="why-title-sm"> Video Captioning</p>
                                </div>
                                <div className="col-md">
                                    <div className="section-03-1-1">
                                        <div className="video-box">
                                            {/* <img src={image1} className="video-image" id="vid-img" /> */}
                                            {/* <video className="video-image" id="vid-img" autoPlay controls>
                                    <source src={video2} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video> */}
                                            <video
                                                className="video-image"
                                                playsInline
                                                loop
                                                muted
                                                autoPlay={true}
                                                // src={video1}
                                                src={`${(currentVideoCaption == 'section3-1') ? video1 : (currentVideoCaption == 'section3-2') ? video2 : (currentVideoCaption == 'section3-3') ? video3 : (currentVideoCaption == 'section3-4') ? video4 : (currentVideoCaption == 'section3-5') ? video5 : null}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="section-03-1-2">
                                        <div className="video-subtitle-box">
                                            <div className={`${(currentVideoCaption == 'section3-1') ? 'active-text' : null}`} id="text1">Subtitle one goes here</div>
                                            <div className={`${(currentVideoCaption == 'section3-2') ? 'active-text' : null}`} id="text2">Subtitle two goes here</div>
                                            <div className={`${(currentVideoCaption == 'section3-3') ? 'active-text' : null}`} id="text3">Third Subtitle goes here</div>
                                            <div className={`${(currentVideoCaption == 'section3-4') ? 'active-text' : null}`} id="text4">Fourth Subtitle goes here</div>
                                            <div className={`${(currentVideoCaption == 'section3-5') ? 'active-text' : null}`} id="text5">Fifth Subtitle goes here</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                ((currentSection == 'section4') && (screenWidth == 'small')) ?
                    <div className={`section-03-1 ${(currentSection == 'section4') ? 'postion-fixed' : 'hidden'}`} id="section-03-1">
                        <div className={`section-03-1 top-margin-md`} id="section-03-1">

                            <div className="row">
                                <div className="col-md">
                                    <p className="why-title-sm"> Social Media Management</p>
                                </div>
                                <div className="col-md">
                                    <div className="section-03-1-1">
                                        <div className="video-box">
                                            {/* <img src={image1} className="video-image" id="vid-img" /> */}
                                            {/* <video className="video-image" id="vid-img" autoPlay controls>
                                    <source src={video2} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video> */}
                                            <video
                                                className="video-image"
                                                playsInline
                                                loop
                                                muted
                                                autoPlay={true}
                                                // src={video1}
                                                src={`${(currentVideoCaption == 'section4-1') ? video1 : (currentVideoCaption == 'section4-2') ? video2 : (currentVideoCaption == 'section4-3') ? video3 : (currentVideoCaption == 'section4-4') ? video4 : (currentVideoCaption == 'section4-5') ? video5 : null}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="section-03-1-2">
                                        <div className="video-subtitle-box">
                                            <div className={`${(currentVideoCaption == 'section4-1') ? 'active-text' : null}`} id="text1">Subtitle one goes here</div>
                                            <div className={`${(currentVideoCaption == 'section4-2') ? 'active-text' : null}`} id="text2">Subtitle two goes here</div>
                                            <div className={`${(currentVideoCaption == 'section4-3') ? 'active-text' : null}`} id="text3">Third Subtitle goes here</div>
                                            <div className={`${(currentVideoCaption == 'section4-4') ? 'active-text' : null}`} id="text4">Fourth Subtitle goes here</div>
                                            <div className={`${(currentVideoCaption == 'section4-5') ? 'active-text' : null}`} id="text5">Fifth Subtitle goes here</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }

            <Modal
                size="xl"
                isOpen={modal_fullscreen}
                toggle={() => {
                    setmodal_fullscreen(!modal_fullscreen);
                }}
                className="modal-fullscreen"
            >
                <div className="main-modal-container">
                    <div className="">
                        <video className="mainContainer" autoPlay muted loop id="myVideo">
                            <source src={video2} type="video/mp4" />
                            Your browser does not support HTML5 video.
                        </video>
                        <div className="main-content-nav">
                            <div className="row gx-0 nav-container-height margin-top">
                                <a href="#section-03" onClick={() => {
                                    setmodal_fullscreen(!modal_fullscreen);
                                }}
                                    className="col-md col-style menu-right-border menu-bottom-border menu-top-border min-height-col ">
                                    <p className="nav-text-new ">Video Captioning</p>
                                </a>
                                <a href="#section-04" onClick={() => {
                                    setmodal_fullscreen(!modal_fullscreen);
                                }}
                                    className="col-md col-style menu-right-border menu-bottom-border min-height-col">
                                    <p className="nav-text-new ">Social Media Management</p>
                                </a>
                                <a href="#section-05" onClick={() => {
                                    setmodal_fullscreen(!modal_fullscreen);
                                }}
                                    className="col-md col-style menu-right-border menu-bottom-border min-height-col">
                                    <p className="nav-text-new ">Our Clients</p>
                                </a>
                                <a href="#section-06" onClick={() => {
                                    setmodal_fullscreen(!modal_fullscreen);
                                }}
                                    className="col-md col-style menu-right-border menu-bottom-border min-height-col">
                                    <p className="nav-text-new ">Our Case Studies</p>
                                </a>
                                <a href="#section-08" onClick={() => {
                                    setmodal_fullscreen(!modal_fullscreen);
                                }}
                                    className="col-md col-style min-height-col menu-bottom-border">
                                    <p className="nav-text-new ">Who we are</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    );
};

export default Main;
